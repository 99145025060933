<template>
    <LiefengContent :isBack="true" @backClick="$router.push('/homepage')">
        <template #title>指标列表</template>
        <!-- <template #toolsbarRight>
            <Form :label-colon="true" :inline="true" :label-width="80">
                、
                <FormItem label="标签类型">
                    <Select transfer v-model="search.tipsCode" style="width: 200px">
                        <Option value="2,3">全部</Option>
                        <Option value="2">重要信息</Option>
                        <Option value="3">党务工作信息</Option>
                    </Select>
                </FormItem>
                <FormItem label="达标状态">
                    <Select transfer v-model="search.status" style="width: 200px">
                        <Option value="">全部</Option>
                        <Option value="1">不达标</Option>
                        <Option value="2">达标</Option>
                    </Select>
                </FormItem>
                <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="searchBtn">查询</Button>
                <Button style="margin-right: 10px" type="success" icon="ios-refresh" @click="resetBtn">重置</Button>
            </Form>
        </template> -->
        <template #contentArea>
            <LiefengTable
                :talbeColumns="columns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: {
        LiefengTable,
        LiefengContent,
    },
    data() {
        return {
            tableData: [],
            columns: [
                {
                    title: "标题",
                    key: "title",
                },
                {
                    title: "范围",
                    key: "scopeName",
                },
                {
                    title: "编码",
                    key: "code",
                },
                {
                    title: "类型",
                    key: "tipCodes",
                    render: (h, params) => {
                        let desc = ""
                        if (params.row.tipCodes && params.row.tipCodes != "" && params.row.tipCodes.includes("2")) {
                            desc = "重要信息"
                        }
                        if (params.row.tipCodes && params.row.tipCodes != "" && params.row.tipCodes.includes("3")) {
                            if (desc.length > 0) {
                                desc = desc + "/" + "党务工作信息"
                            } else {
                                desc = "党务工作信息"
                            }
                        }
                        return h("div", {}, desc)
                    },
                },
                {
                    title: "所属月份",
                    key: "yearOfMonth",
                },
                {
                    title: "推送率(%)",
                    key: "rate",
                    render: (h, params) => {
                        const content = "推送量：" + params.row.readUser + "人,注册用户：" + params.row.userRegister + "人"
                        return h(
                            "div",
                            {
                                style: {
                                    lineHeight: "100%",
                                },
                            },
                            [
                                h("span", {
                                    domProps: {
                                        innerText: params.row.rate,
                                    },
                                }),
                                h(
                                    "Tooltip",
                                    {
                                        props: {
                                            placement: "bottom",
                                            maxWidth: "500",
                                            transfer: true,
                                            content,
                                        },
                                    },
                                    [
                                        h("Icon", {
                                            props: {
                                                type: "md-help-circle",
                                            },
                                            style: {
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                margin: "0 10px",
                                            },
                                        }),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,
            search: {
                tipsCode: "2,3",
                status: "",
            },
        }
    },

    methods: {
        searchBtn() {
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        resetBtn() {
            this.page = 1
            this.pageSize = 20
            this.search.status = ""
            this.search.tipsCode = "2,3"
            this.getList()
        },
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        getList() {
            const requestJson = {
                orgCode: JSON.parse(sessionStorage.getItem("userDetail")).communityCode,
                // ...this.search,
                page: this.page,
                pageSize: this.pageSize,
                tipsCode:'2'
            }
            this.$get("/gateway/syinfopublish/api/pc/informationContract/selectPage", requestJson).then(res => {
                if (res.code == 200) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.page = res.currentPage
                }
            })
        },
    },
    created() {
        this.getList()
    },
}
</script>

<style scoped lang='less'>
</style>